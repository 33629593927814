import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm, Spin, Tooltip, message } from "antd";
// import Icons from "../../../components/icons";
import AddNewPlan from "./lib/add-new-plan";
import Icons from "../../../../components/icons";
import { numberformat } from "../../../../config";
import { MakeApiCall } from "../../../../apis";

const ManageSbuscriptions = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [editObj, setEditObj] = useState({});
  const [addNewPlan, setAddNewPlan] = useState(false);

  const [loading, setLoading] = useState(true);
  const [cardList, setCardList] = useState([]);

  const [serviceLoading, setServiceLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);

  const confirm = (d) => {
    modal.confirm({
      title: `Confirmation`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          {" "}
          {`Are you sure, you want to ${d?.status === 1 ? "de" : ""}activate `}
          <b>{d?.plan_name}</b> plan ?
        </div>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // DeactivatePlan(d);
      },
      onCancel: () => {},
    });
  };

  const getServicesList = async () => {
    try {
      const response = await MakeApiCall(`services`, "get", null, true);

      if (response?.status) {
        setServiceList(response?.data || []);
        setServiceLoading(false);
      } else {
        setServiceLoading(false);
        setServiceList([]);
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      setServiceList([]);
      message.error("something went wrong");
      setServiceLoading(false);
    }
  };
  const getPlanList = async () => {
    try {
      const response = await MakeApiCall(`plans`, "get", null, true);

      if (response?.status) {
        setCardList(response?.data || []);
        setLoading(false);
      } else {
        setLoading(false);
        setCardList([]);
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      setCardList([]);
      message.error("something went wrong");
      setLoading(false);
    }
  };
  const deletePlan = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `plan/active-plans/${id}`,
        "POST",
        null,
        true
      );

      if (response?.status) {
        setLoading(true);
        getPlanList();
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error("something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    getPlanList();
    getServicesList();
    return () => {};
  }, []);

  return (
    <Wrapper className="d-flex flex-column flex-column-fluid mt-3">
      {contextHolder}
      <>
        <div className="app-content flex-column-fluid " id="kt_app_content">
          <div
            className="fadeInRight content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-fluid" id="kt_content_container">
              <h2 className="mb-6 smallScreenPageHeader">Pricing Plans</h2>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" id="kt_pricing">
                    <div className="card-header border-bottom border-bottom-dashed">
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        <></>
                      </div>
                      <div className="card-toolbar">
                        <div className="position-relative mr-4">
                          <button
                            className="btn btn-primary"
                            onClick={() => setAddNewPlan(true)}
                          >
                            <PlusOutlined className="me-2" />
                            Add New Plan
                          </button>
                        </div>
                      </div>
                    </div>

                    <>
                      <Spin spinning={loading} tip="Loading...">
                        <div className="card-body p-lg-17 mb-5">
                          <div className="d-flex flex-column">
                            <div className="row justify-content-center">
                              {cardList?.map((d, i) => {
                                return (
                                  d?.status === 1 && (
                                    <div className="col-xl-4" key={i}>
                                      <div
                                        className="d-flex h-100 align-items-center position-relative"
                                        style={{}}
                                      >
                                        <div
                                          className="d-flex justify-content-end"
                                          style={{
                                            position: "absolute",
                                            top: 10,
                                            right: 10,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <EditTwoTone
                                            style={{
                                              fontSize: "24px",
                                            }}
                                            twoToneColor="#000"
                                            onClick={() => {
                                              setEditObj(d);
                                              setAddNewPlan(true);
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="w-100 d-flex flex-column flex-center rounded-3  py-15 px-10"
                                          style={{
                                            borderTop: "2px solid #e6542a",
                                            background: "#FFF",
                                            boxShadow:
                                              "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                          }}
                                        >
                                          <div className="mb-7 text-center">
                                            {/*begin::Title*/}
                                            <h1 className="text-gray-900 mb-5 fw-bolder">
                                              {d?.plan_name}
                                            </h1>

                                            <div className="text-center">
                                              <span className="mb-2 text-primary">
                                                $
                                              </span>
                                              <span className="fs-3x fw-bold text-primary">
                                                {d?.plan_subscription_cost || 0}{" "}
                                              </span>
                                              <span className="fs-7 fw-semibold opacity-50">
                                                /
                                                <span data-kt-element="period">
                                                  m Per {d?.type}
                                                </span>
                                              </span>
                                            </div>
                                            {/*end::Price*/}
                                          </div>
                                          <div
                                            className="w-100 mb-5 mh-200px min-h-200px"
                                            style={{ overflow: "auto" }}
                                          >
                                            {/*begin::Item*/}
                                            {d?.services.map((r, i) => (
                                              <div
                                                key={i}
                                                className="d-flex align-items-center mb-5"
                                              >
                                                <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                  {r?.service_name}{" "}
                                                </span>
                                                <svg
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    opacity="0.3"
                                                    x={2}
                                                    y={2}
                                                    width={20}
                                                    height={20}
                                                    rx={10}
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </div>
                                            ))}
                                            {/*end::Item*/}
                                          </div>
                                          <div
                                            className="w-100 mb-10"
                                            style={{
                                              borderTop: "1px solid grey",
                                            }}
                                          >
                                            <div className="d-flex align-items-center mb-5 mt-5">
                                              <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                Plan include orders upto:
                                              </span>
                                              <span>
                                                {numberformat(d?.no_orders)}
                                              </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                              <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                No. of user included:
                                              </span>
                                              <span>5 Users</span>
                                            </div>
                                            <div className="d-flex align-items-center mb-5">
                                              <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                                                No. of marketplace included:
                                              </span>
                                              <span>1 Pri, 5 Sec</span>
                                            </div>
                                            <div
                                              data-test="_ * 30 days free trial. You will be charged after the trial period."
                                              className=" line-clamp"
                                              style={{
                                                WebkitLineClamp: 1,
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-all",
                                                borderTop: "1px solid grey",
                                                paddingTop: "10px",
                                              }}
                                            >
                                              * 30 days free trial. You will be
                                              charged after the trial period.
                                            </div>
                                          </div>
                                          <Popconfirm
                                            title={`${
                                              d?.deleted_at
                                                ? "Re-Activate"
                                                : "Deactivate"
                                            } the ${d?.plan_name}`}
                                            description={`Are you sure to ${
                                              d?.deleted_at
                                                ? "re-activate"
                                                : "deactivate"
                                            } this plan?`}
                                            onConfirm={() => {
                                              // if (d?.deleted_at) {
                                              //   // call re-activate
                                              //   return;
                                              // }
                                              deletePlan(d?.id);
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              style={{
                                                backgroundColor: d?.deleted_at
                                                  ? "black"
                                                  : "",
                                                color: "#FFF",
                                              }}
                                              type={
                                                d?.deleted_at
                                                  ? "ghost"
                                                  : `primary`
                                              }
                                            >
                                              {d?.deleted_at
                                                ? "Activate Plan"
                                                : "Deactivate Plan"}
                                            </Button>
                                          </Popconfirm>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {addNewPlan && (
          <AddNewPlan
            show={addNewPlan}
            serviceList={serviceList}
            serviceLoading={serviceLoading}
            editObj={editObj}
            getPlans={() => {
              setLoading(true);
              getPlanList();
            }}
            onHide={() => {
              setAddNewPlan(false);
              setEditObj({});
            }}
          />
        )}
      </>
    </Wrapper>
  );
};

export default ManageSbuscriptions;
