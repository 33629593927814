import React, { useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { mode } from "./core/helper/theme";
import NoData from "./components/no-data";
import { ConfigProvider, theme } from "antd";
import { store } from "./redux/configurations/store/configure-store";
import AppStyleWrapper from "./AppStyle";
import AppRoute from "./AppRouter";
import { createHashHistory } from "history";
import { GlobalContext } from "./commonContext";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeID } from "./config";
const stripePromise = loadStripe(StripeID);
export default function () {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const { toggle } = useContext(GlobalContext);

  return (
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={mode?.[toggle]}>
          <ConfigProvider
            renderEmpty={() => <NoData />}
            theme={{
              algorithm: defaultAlgorithm,
              token: {
                fontFamily: "Roboto, sans-serif",
                colorPrimary: "#e6542a",
                colorPrimaryHover: "#e6542a",
              },
            }}
          >
            <Provider store={store}>
              <AppStyleWrapper />
              <AppRoute history={createHashHistory()} />
            </Provider>
          </ConfigProvider>
        </ThemeProvider>
      </Elements>
    </React.StrictMode>
  );
}
