import moment from "moment";
import SidebarIcons from "../components/icons/sidebar";
import dayjs from "dayjs";
import { convertSidebarLinks } from "./sidebar";
export const AppName = "ZonKeepers";
export const EndPoint = "https://analytics.zonkeepers.com.au/zonkeepers_api/";
export const colorHex = "#fe763a";
export const API_URL = `${EndPoint}api/v1/`;
export const StripeID =
  "pk_live_51OJW8jCVfjSG8CqsKbiv7ONNmdcm3v4d3p2InZK4MSkVf0OLhPKJ0VefULQavUQlcVF1tifCy4HyS73l6f63yZVf00Wj9guYgZ";
export const imageURL = `${EndPoint}images/user-images/`;
export const pageDropdown = [10, 25, 50, 100];
export const APPID = "amzn1.sp.solution.f80d1a7c-8798-4e7f-9c84-b00beb83e2f4";
export const DefaultPerPage = 10;
export const DateFormat = "DD-MM-YYYY";
export const noImage =
  "https://t3.ftcdn.net/jpg/04/84/88/76/360_F_484887682_Mx57wpHG4lKrPAG0y7Q8Q7bJ952J3TTO.jpg";

function hexToRgbA(hex, op) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      (op || 1) +
      ")"
    );
  }
  throw new Error("Bad Hex");
}
export const numberformat = (value) => {
  return parseFloat(value || 0).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};
function changeColor(color, amount) {
  const clamp = (val) => Math.min(Math.max(val, 0), 0xff);
  const fill = (str) => ("00" + str).slice(-2);

  const num = parseInt(color.substr(1), 16);
  const red = clamp((num >> 16) + amount);
  const green = clamp(((num >> 8) & 0x00ff) + amount);
  const blue = clamp((num & 0x0000ff) + amount);
  return (
    "#" +
    fill(red.toString(16)) +
    fill(green.toString(16)) +
    fill(blue.toString(16))
  );
}
export const themeColor = (op, type) => {
  if (type === "hover") {
    return changeColor(colorHex, -100);
  } else if (type === "light") {
    return changeColor(colorHex, 100);
  } else if (type) {
    return hexToRgbA(colorHex, op);
  }
  return colorHex;
};
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
export const clearLocalData = () => {
  localStorage.clear();
  window.location.assign("/login");
  window.location.reload();
};
// export const handleStorageChange = (e) => {
//   e.stopPropagation();
//   if (e.url === window.location.href) {
//     if (!e.key) {
//       clearLocalData();
//     } else {
//       localStorage.setItem(e.key, e.oldValue);
//     }
//   }
//   if (
//     !localStorage.getItem("token") ||
//     !localStorage.getItem("user") ||
//     !localStorage.getItem("userType") ||
//     !localStorage.getItem("menusList")
//   ) {
//     clearLocalData();
//   }
// };
export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date * 1000) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const testArr = [
  "dashboard",
  "sales-analytics",
  "ad-summary",
  "advertising-reports",
  "finance-api",
  "mom-&-yoy-comparison",
  "mapping",
  "manage-inventory",
  "reserved-inventory",
  "setting",
  "application-logs",
];
export const adminMenus = ["manage-seller-users"];
const GetIcons = (icon) => {
  return <SidebarIcons type={icon} />;
};

const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

const Modules = [
  {
    title: "Dashboard",
    name: "dashboard",
    path: ["/", "/dashboard"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("dashboard"),
  },
  {
    title: "Profit & Loss Analytics",
    name: "profit-&-loss-analytics",
    path: ["/profit-&-loss-analytics"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("profit-analytics"),
  },
  {
    title: "P & L Breakdown",
    name: "p&l-breakdown",
    path: ["/p&l-breakdown"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("p&l-breakdown"),
  },

  {
    title: "Sales Analytics",
    name: "sales-analytics",
    path: ["/sales-analytics"],
    index: [
      {
        admin: -1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("sales-analytics"),
  },

  {
    title: "Landed Costs",
    name: "landed-costs",
    path: ["/landed-costs"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("cogs-mapping"),
  },

  {
    title: "Manage User",
    name: "manage-seller-users",
    path: ["/manage-seller-users"],
    index: [
      {
        admin: 1,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("manage-seller-users"),
  },
  {
    title: "Manage Subscriptions",
    name: "manage-subscriptions",
    path: ["/manage-subscriptions"],
    index: [
      {
        admin: 2,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("manage-subscriptions"),
  },
  // {
  //   title: "Settings",
  //   name: "setting",
  //   path: ["/setting"],
  //   index: [
  //     {
  //       admin: 3,
  //       common: -1,
  //     },
  //   ],
  //   rule: ["admin"],
  //   icon: GetIcons("setting"),
  //   children: [
  //     {
  //       title: "App Configurations",
  //       name: "app-configurations",
  //       path: ["/app-configurations"],
  //       index: [],
  //       parent: "setting",
  //       icon: bullet,
  //     },
  //   ],
  // },
  {
    title: "Inventory",
    name: "inventory",
    path: ["/inventory"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("reports-overall-analytics"),
  },
  // {
  //   title: "My Subscriptions",
  //   name: "my-subscriptions",
  //   path: ["/", "/my-subscriptions"],
  //   index: [
  //     {
  //       admin: 1,
  //       common: 1,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("my-subscriptions"),
  // },
  {
    title: "Application Logs",
    name: "application-logs",
    path: ["/application-logs"],
    index: [
      {
        admin: -1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("application-logs"),
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: ["/central-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "System Events Log",
        name: "system-events-log",
        path: ["/system-events-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
    ],
  },
  {
    title: "Help & Support",
    name: "help-&-support",
    path: ["/help-&-support"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("help-&-support"),
  },
];

export const MyNewSidebarFunc = (userMenus, mode) => {
  if (mode === "admin") {
    return userMenus;
  }
  const menuList = localStorage.getItem("menusList")
    ? JSON.parse(localStorage.getItem("menusList")) || []
    : [];
  let menus = [];
  userMenus.forEach((d) => {
    const index = menuList?.findIndex((a) => a === d.key);
    if (index !== -1) {
      menus.push(d);
    }
  });
  return menus;
};

export const ModulesList = (props) => {
  const { type, mode } = props;
  if (type === "sidebar") {
    return convertSidebarLinks(
      Modules?.filter((d) => d?.rule?.findIndex((t) => t === mode) !== -1),
      mode
    );
  } else if (type === "url") {
    let obj = {};
    Modules.forEach((r) => {
      r.path.forEach((p) => {
        obj = {
          ...obj,
          [p]: {
            name: r.title,
            parent: r.parentName,
            path: r.parentPath,
          },
        };
      });
      r?.children?.forEach((p) => {
        p.path.forEach((i) => {
          obj = {
            ...obj,
            [i]: {
              name: p?.title,
              path: r?.parentPath + i,
              parent: r?.title,
            },
          };
        });
      });
    });
    return obj;
  }
  return [];
};

const getIds = (type, marketplaceList, d, getType) => {
  const marketplace = marketplaceList?.[d];
  return type === "number"
    ? getType === "values"
      ? d
      : marketplace
    : getType === "label"
    ? marketplace?.marketplace
    : marketplace?.marketplace_id;
};
export const MarketplaceFlag = (marketplaceList, type = "number") =>
  Object.keys(marketplaceList).map((d, i) => {
    const obj_ = type === "number" ? d : marketplaceList?.[d];
    return {
      value: getIds(type, marketplaceList, d, "values"),
      label: (
        <>
          <img
            src={`/zonkeeper-media/domainImage/${getIds(
              type,
              marketplaceList,
              d,
              "image"
            )}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/zonkeeper-media/domainImage/red-flag.png";
            }}
          />
          {getIds(type, marketplaceList, d, "label")}
        </>
      ),
      ...obj_,
    };
  });

const currentMonth = moment().month(); // Get the current month (0-indexed)
const quarterStart = moment()
  .startOf("year")
  .add(currentMonth - (currentMonth % 3), "months");
const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

const lastQuarterStart = moment()
  .startOf("year")
  .add(currentMonth - (currentMonth % 3) - 3, "months");
const lastQuarterEnd = lastQuarterStart
  .clone()
  .add(3, "months")
  .subtract(1, "day");

export const RangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
    valueTiles: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    valueTiles: [
      dayjs().add(-1, "d").format("YYYY-MM-DD"),
      dayjs().add(-1, "d").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "This Month", //
    value: [dayjs().startOf("month"), dayjs().endOf("month")],
    valueTiles: [
      dayjs().startOf("month").format("YYYY-MM-DD"),
      dayjs().endOf("month").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "This Quarter",
    value: [dayjs(quarterStart), dayjs(quarterEnd)],
    valueTiles: [
      dayjs(quarterStart).format("YYYY-MM-DD"),
      dayjs(quarterEnd).format("YYYY-MM-DD"),
    ],
  },
  {
    label: "Year To Date", // 2021
    value: [dayjs().startOf("year"), dayjs()],
    valueTiles: [
      dayjs().startOf("year").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().startOf("month").subtract(1, "month"),
      dayjs().startOf("month").subtract(1, "day"),
    ],
    valueTiles: [
      dayjs().startOf("month").subtract(1, "month").format("YYYY-MM-DD"),
      dayjs().startOf("month").subtract(1, "day").format("YYYY-MM-DD"),
    ],
  },
  {
    label: "Last Quarter",
    value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],

    valueTiles: [
      dayjs(lastQuarterStart).format("YYYY-MM-DD"),
      dayjs(lastQuarterEnd).format("YYYY-MM-DD"),
    ],
  },
  {
    label: "Last Year",
    value: [
      dayjs().startOf("year").subtract(1, "year"),
      dayjs().startOf("year").subtract(1, "day"),
    ],
    valueTiles: [
      dayjs().startOf("year").subtract(1, "year").format("YYYY-MM-DD"),
      dayjs().startOf("year").subtract(1, "day").format("YYYY-MM-DD"),
    ],
  },

  {
    label: "Custom Range",
    value: [0, 0],
  },
];
