import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Divider, Dropdown, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../commonContext";
import Icons from "../../../components/icons";
import ASINLink from "../../../components/router-link";
import { AppName, testArr } from "../../../config";
import { nameObject } from ".././../../core/helper/static";
import Wrapper from "./style";
import { Link, useLocation } from "react-router-dom";
import ChangePasswordModal from "./lib/change-password";
import { MakeApiCall } from "../../../apis";
import BusinessInfo from "./lib/business";
import BacktoAdmin from "./lib/backtoAdmin";

export default function (props) {
  const {
    fakeActionUser,
    setCollapsed,
    collapsed,
    backToUser,
    userType,
    backToAdmin,
    history,
    logout,
    hideMenus,
    setHideMenus,
  } = props;
  const location = useLocation();
  const [current, setCurrent] = useState({
    name: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const contextValue = useContext(GlobalContext);
  const SwitchUserResponse = useSelector(
    (state) => state.User.SwitchUserResponse || {}
  );
  const user = contextValue.data?.user_;
  useEffect(() => {
    if (SwitchUserResponse.status) {
      backToUser(SwitchUserResponse?.data?.user_data);
      history.replace("/dashboard");
      fakeActionUser("SwitchUserResponse");
    }
  }, [SwitchUserResponse]);
  useEffect(() => {
    if (nameObject[history.location.pathname]) {
      setCurrent(nameObject[history.location.pathname]);
    } else {
      if (
        Object.keys(nameObject).findIndex((d) =>
          d.includes("/" + history.location.pathname.split("/")[1])
        ) !== -1
      ) {
        return setCurrent({
          name: nameObject["/" + history.location.pathname.split("/")[1]],
        });
      }
      setCurrent({ name: "" });
    }
  }, [history?.location]);

  document.title = current?.name || AppName;

  const contextVar = useContext(GlobalContext);

  const onSubmit = async (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(`change-password`, "post", value, true);

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setForgotPassword(false);
    } else {
      message.destroy();

      message.error(
        Object.values(response?.error || {})?.[0]?.[0] || response?.message
      );
    }
  };

  useEffect(() => {
    if (
      location?.pathname === "/callback_sp" ||
      location?.pathname === "/callback_ads" ||
      location?.pathname === "/my-subscriptions"
    )
      return;
    if (
      location?.pathname === "/setting/sp-api" &&
      contextVar?.data?.user_?.sp_credential === 1
    )
      return;
    if (
      contextVar?.data?.subscriptions?.service?.length === 0 &&
      contextVar?.data?.user_?.u_type !== 1 &&
      location?.pathname !== "/my-subscriptions"
    ) {
      return history.replace("/my-subscriptions");
    } else if (
      contextVar?.data?.subscriptions?.service?.length !== 0 &&
      contextVar?.data?.user_?.sp_credential === 0 &&
      location?.pathname !== "/setting/sp-api"
    ) {
      return history.replace("/setting/sp-api");
    } else if (
      contextVar?.data?.subscriptions?.service?.length !== 0 &&
      contextVar?.data?.user_?.sp_credential === 1 &&
      contextVar?.data?.user_?.advetising === 0 &&
      location?.pathname !== "/setting/amazon-credentials"
    ) {
      return history.replace("/setting/amazon-credentials");
    }
  }, [location?.pathname]);

  const menus = [
    {
      key: "1",
      disabled: user?.advetising === 0 || user?.sp_credential === 0,
      label: <Link to="/setting/profile">Profile</Link>,
    },
    {
      key: "6",
      disabled: user?.advetising === 0 || user?.sp_credential === 0,
      label: (
        <div
          onClick={() => {
            if (user?.advetising === 1 && user?.sp_credential === 1) {
              setIsModalVisible(true);
            }
          }}
        >
          Business Details
        </div>
      ),
    },
    {
      key: "2",
      disabled: user?.advetising === 0 || user?.sp_credential === 0,
      label: (
        <div
          onClick={() => {
            if (user?.advetising === 1 && user?.sp_credential === 1) {
              setForgotPassword(true);
            }
          }}
        >
          Change Password
        </div>
      ),
    },
    {
      key: "6",

      label: <Link to="/my-subscriptions">My Subscriptions</Link>,
    },

    {
      key: "3",
      label: <Link to="/setting/sp-api">SP API Credentials</Link>,
    },
    {
      key: "4",
      disabled: user?.sp_credential === 0,
      label: (
        <Link to="/setting/amazon-credentials">Advertising Credentials</Link>
      ),
    },
    {
      key: "5",

      label: <div onClick={() => logout()}>Logout</div>,
    },
  ];

  const ImageEnd =
    "https://analytics.zonkeepers.com.au/zonkeepers_api/images/user-images/";
  const {
    u_name,
    u_email,
    u_id,
    photo,
    business_logo,
    business_address,
    business_name,
  } = contextValue?.data?.user_;

  return (
    <>
      <Wrapper id="kt_header" className=" header authFadeInBottom">
        <div
          className={`d-flex align-items-center flex-wrap ${
            window.innerWidth >= 690
              ? "justify-content-between"
              : window.innerWidth <= 690
              ? !hideMenus
                ? "justify-content-between"
                : "justify-content-between"
              : "justify-content-between"
          } ${
            window.innerWidth >= 400
              ? "container-fluid"
              : "container-fluid-sm w-100"
          }`}
          id="kt_header_container"
        >
          <div
            className={`d-flex align-items-center ${
              window.innerWidth <= 690 ? (!hideMenus ? " " : " ") : ""
            }`}
            style={{}}
          >
            {(690 > window.innerWidth || hideMenus) && (
              <div
                onClick={() => setHideMenus(!hideMenus)}
                style={{
                  transition: "0.6s",
                  transform: `translateX(${!hideMenus ? "95" : "0"}px)`,
                  background: !hideMenus ? "#0091e2" : "transparent",
                  zIndex: 99,
                  padding: "10px 10px 10px 0px",
                }}
                className="btn btn-icon btn-active-icon-primary"
              >
                <span className="svg-icon svg-icon-1 svg-icon-primary mt-1">
                  {!hideMenus ? (
                    <CloseOutlined
                      style={{
                        color: "white",
                        marginLeft: "10px",
                      }}
                    />
                  ) : (
                    <Icons type="HeaderSvg" />
                  )}
                </span>
              </div>
            )}
            <div
              className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2  pb-lg-0 pt-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
              style={{
                marginLeft:
                  window.innerWidth <= 690 && window.innerWidth >= 500
                    ? !hideMenus
                      ? "16%"
                      : ""
                    : window.innerWidth < 500 && window.innerWidth > 400
                    ? !hideMenus
                      ? "-100%"
                      : ""
                    : window.innerWidth < 400
                    ? !hideMenus
                      ? "-100%"
                      : ""
                    : "0",

                transition: "0.6s",
                position: "relative",
                top: "5px",
              }}
            >
              <Breadcrumb separator={`${current.name ? "/" : ""}`}>
                {testArr?.findIndex((d) => d === "dashboard") !== -1 && (
                  <Breadcrumb.Item>
                    <ASINLink
                      to={{
                        pathname: `${
                          localStorage.getItem("adminData")
                            ? "/"
                            : "/manage-seller-users"
                        }`,
                      }}
                    >
                      <span
                        style={{
                          color: `${
                            current?.name === "Dashboard"
                              ? "#626262"
                              : "#898989c9"
                          }`,
                          position: "relative",
                          top: "-1px",
                        }}
                      >
                        Home
                      </span>
                    </ASINLink>
                  </Breadcrumb.Item>
                )}

                {current?.name !== "Dashboard" && (
                  <>
                    <Breadcrumb.Item className="fw-bolder">
                      {current?.parent ? (
                        <ASINLink
                          to={{
                            pathname: `${current?.path ? current?.path : ""}`,
                          }}
                        >
                          <span
                            style={{
                              color: `${
                                current?.parent ? "#898989c9" : "#626262"
                              }`,
                              position: "relative",
                              top: "-1px",
                            }}
                          >
                            {current?.name
                              ? current?.parent
                                ? current?.parent
                                : current?.name
                              : ""}
                          </span>
                        </ASINLink>
                      ) : (
                        current?.name || ""
                      )}
                    </Breadcrumb.Item>
                    {current?.parent && (
                      <Breadcrumb.Item className="fw-bolder">
                        <span
                          className="dashboardTitle"
                          style={{
                            color: "#626262",
                          }}
                        >
                          {current?.name || ""}
                        </span>
                      </Breadcrumb.Item>
                    )}
                  </>
                )}
              </Breadcrumb>
            </div>
          </div>

          <div className="d-flex align-items-center ms-1">
            {localStorage.getItem("adminData") && (
              <>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="me-2"
                >
                  <BacktoAdmin onClick={() => backToAdmin()} />
                </div>
                <Divider
                  style={{ borderInlineStart: "1px solid #e6542a" }}
                  type="vertical"
                />
              </>
            )}
            {!userType && (
              <>
                <div className="app-navbar-item ms-1 ms-md-3 me-4">
                  <img
                    src={
                      ImageEnd + business_logo ||
                      "https://static.wixstatic.com/media/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png"
                    }
                    onError={(e) =>
                      (e.target.src =
                        "https://static.wixstatic.com/media/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png")
                    }
                    className="h-35px"
                    alt
                  />
                </div>
                <Divider
                  style={{ borderInlineStart: "1px solid #e6542a" }}
                  type="vertical"
                />
              </>
            )}
            <Dropdown
              menu={{
                items:
                  contextValue?.data?.user_?.u_type === 1
                    ? [
                        {
                          key: "5",
                          label: <div onClick={() => logout()}>Logout</div>,
                        },
                      ]
                    : menus,
              }}
              trigger={["click"]}
              placement="bottom"
            >
              <div
                onClick={(e) => e.preventDefault()}
                className="btn btn-flex align-items-center bg-hover-dark bg-hover-opacity-10 py-2 ps-2 pe-2 me-n2"
              >
                <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
                  <span className="fw-bold d-flex align-items-center fs-5 mb-1">
                    {u_name || "-"}
                  </span>
                  <span className="fw-semibold text-muted text-hover-primary fs-7 ">
                    {u_email || "-"}
                  </span>
                </div>
                <Avatar
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#e6542a",
                    border: "1px solid #e6542a",
                  }}
                  size={50}
                >
                  {u_name?.charAt(0).toUpperCase()}
                </Avatar>
              </div>
            </Dropdown>
          </div>
        </div>
        <ChangePasswordModal
          visible={forgotPassword}
          onCancel={() => setForgotPassword(false)}
          onSubmit={onSubmit}
        />
        {isModalVisible && (
          <BusinessInfo
            id={u_id}
            isModalVisible={isModalVisible}
            contextValue={contextValue}
            business_address={business_address}
            business_logo={business_logo}
            business_name={business_name}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      </Wrapper>
    </>
  );
}
